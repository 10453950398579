import AuthTypes from '../types'
import { ActionCreatorTypes } from '../../../reducers/types'
import { LOGIN, LOGOUT } from '../actions'

const INITIAL_STATE: AuthTypes.InitialStateAuth = {
  loading: true,
  isLogged: false,
  error: null
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): AuthTypes.InitialStateAuth => {
  switch (type) {
    case LOGIN:
      return { ...state, isLogged: payload, loading: false }
    case LOGOUT:
      return { ...state, isLogged: false, loading: false }
    default:
      return state
  }
}
