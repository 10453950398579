// Actions
import {
  REQUEST_DATA_REWARDS,
  SUCCESS_DATA_REWARDS,
  ERROR_DATA_REWARDS
} from '../actions'
// Types
import { ActionCreatorTypes } from '../../../reducers/types'
import { PayloadDataRewards, PayloadErrorRewards } from '../types'

interface RequestPayloadTypes {
  storeID: string
  month: string
  year: string
}

export const requestDataRewards = (
  payload: RequestPayloadTypes
): ActionCreatorTypes => ({
  type: REQUEST_DATA_REWARDS,
  payload
})
export const successDataRewards = (
  payload: PayloadDataRewards
): ActionCreatorTypes => ({
  type: SUCCESS_DATA_REWARDS,
  payload
})

export const errorDataRewards = (
  payload: PayloadErrorRewards
): ActionCreatorTypes => ({
  type: ERROR_DATA_REWARDS,
  payload
})
