// Types
import { ActionCreatorTypes } from '../../reducers/types'
import {
  InitialStateWorkers,
  PayloadDataWorkersTypes,
  PayloadPermissionWorker,
  PayloadAllPermissions
} from './types'

/**********ACTION & ACTION CREATOR***********/

export const SAVE_DATA_MOCK = 'SAVE_DATA_MOCK'
export const SAVE_PERMISSION_WORKER = 'SAVE_PERMISSION_WORKER'
export const SAVE_ALL_PERMISSIONS_WORKER = 'SAVE_ALL_PERMISSIONS_WORKER'
export const SAVE_ALL_BASIC_DATA_WORKER = 'SAVE_ALL_BASIC_DATA_WORKER'

export const saveDataMock = (
  payload: PayloadDataWorkersTypes
): ActionCreatorTypes => ({
  type: SAVE_DATA_MOCK,
  payload
})
export const savePermissionWorker = (
  payload: PayloadPermissionWorker
): ActionCreatorTypes => ({
  type: SAVE_PERMISSION_WORKER,
  payload
})
export const saveAllPermissionsWorker = (
  payload: PayloadAllPermissions
): ActionCreatorTypes => ({
  type: SAVE_ALL_PERMISSIONS_WORKER,
  payload
})
export const saveAllBasicDataWorker = (
  payload: PayloadDataWorkersTypes
): ActionCreatorTypes => ({
  type: SAVE_ALL_BASIC_DATA_WORKER,
  payload
})

/**********REDUCER***********/

const INITIAL_STATE: InitialStateWorkers = {
  isLoading: false,
  workers_data: [],
  error: ''
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateWorkers => {
  switch (type) {
    case SAVE_ALL_BASIC_DATA_WORKER:
      return { ...state, workers_data: payload }
    case SAVE_ALL_PERMISSIONS_WORKER:
      return {
        ...state,
        workers_data: state.workers_data.map((worker: any, idx: number) => {
          const { idxWorker, node, permissions } = payload
          if (idxWorker === idx) {
            worker[node] = permissions
          }
          return worker
        })
      }
    case SAVE_PERMISSION_WORKER:
      return {
        ...state,
        workers_data: state.workers_data.map((worker: any, idx: number) => {
          const { idxWorker, node, key, value } = payload
          if (idxWorker === idx) {
            worker[node][key] = value
          }
          return worker
        })
      }
    // deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
    case SAVE_DATA_MOCK:
      return { ...state, workers_data: payload }
    default:
      return state
  }
}
