export const isBrowser: () => boolean = () => typeof window !== 'undefined'

export const getIdToken: () => void = () =>
  isBrowser() && window.localStorage.getItem('idToken')
    ? window.localStorage.getItem('idToken')
    : {}

export const getAccessTokenReturn = () => {
  return isBrowser() && window.localStorage.getItem('accessToken')
    ? window.localStorage.getItem('accessToken')
    : {}
}

export const getAccessToken: () => void = () =>
  isBrowser() && window.localStorage.getItem('accessToken')
    ? window.localStorage.getItem('accessToken')
    : {}

export const getRefreshToken: () => void = () =>
  isBrowser() && window.localStorage.getItem('refreshToken')
    ? window.localStorage.getItem('refreshToken')
    : {}
export const getUser: () => any = () =>
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser') || '')
    : {}

export const setUser: (user: string) => void = (user) =>
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user))

export const setIdToken: (idToken: string) => void = (idToken) =>
  window.localStorage.setItem('idToken', idToken)

export const setAccessToken: (accessToken: string) => void = (accessToken) =>
  window.localStorage.setItem('accessToken', accessToken)

export const setRefreshToken: (refreshToken: string) => void = (refreshToken) =>
  window.localStorage.setItem('refreshToken', refreshToken)

export const setParametersUser: (data: any) => void = (data) => {
  setIdToken(data.IdToken)
  setAccessToken(data.accessToken)
  setRefreshToken(data.refreshToken)
  setUser(data)
}

export const deleteParametersUser: () => void = () => {
  if (isBrowser()) {
    window.localStorage.removeItem('idToken')
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('gatsbyUser')
    window.localStorage.clear()
    sessionStorage.clear()
  }
}

export const isLoggedIn: () => any = () => {
  const user = getUser()
  return !!user.phone_number
}

export const getMainPathByRole: () => any = () => {
  if (isBrowser()) {
    switch (getSessionStorageItem('levelSecurity')) {
      case '1':
      case '2':
        return '/dashboard'
      default:
        return '/profile'
    }
  }
}

export const changePassword: (
  email: any,
  password: any,
  user: any,
  userCognito: any
) => void = (email, password, user, userCognito) => {
  delete user.email_verified
  delete user.phone_number_verified
  user.name = email

  return new Promise((resolve, reject) => {
    userCognito.completeNewPasswordChallenge(password, user, {
      onFailure(err: any) {
        reject(err)
      },
      onSuccess(result: any) {
        setUser(result)
        resolve(result)
      }
    })
  })
}

export const getLocalStorageItem = (item: string): string | false | null =>
  isBrowser() && window.localStorage.getItem(item)

export const setLocalStorageItem = (item: string, payload: string): void => {
  if (isBrowser()) {
    window.localStorage.setItem(item, payload)
  }
}

export const deleteLocalStorageItem = (item: string): void => {
  if (isBrowser()) {
    window.localStorage.removeItem(item)
  }
}

export const getSessionStorageItem = (item: string): string | false | null =>
  isBrowser() && window.sessionStorage.getItem(item)

export const setSessionStorageItem = (item: string, payload: string): void =>
  window.sessionStorage.setItem(item, payload)

export const deleteSessionStorageItem = (item: string): void => {
  if (isBrowser()) {
    window.sessionStorage.removeItem(item)
  }
}
