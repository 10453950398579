// Packages
import { fork, all } from 'redux-saga/effects'

// Saga
import { watcherGetProfiles } from '../ducks/profile'
import { watcherGetRewardsReport } from '../ducks/rewardsGraphicsReport/sagas'

export default function* rootSaga(): Generator {
  yield all([fork(watcherGetProfiles), fork(watcherGetRewardsReport)])
}
