// Actions
import {
  REQUEST_DATA_REWARDS,
  SUCCESS_DATA_REWARDS,
  ERROR_DATA_REWARDS
} from '../actions'
// Types
import { ActionCreatorTypes } from '../../../reducers/types'
import { PayloadDataRewards } from '../types'

export interface InitialStateRewardsTypes {
  isLoading: boolean
  rewards_data: PayloadDataRewards
  error: string
}

const INITIAL_STATE = {
  isLoading: false,
  rewards_data: {
    getBonusReport: [
      {
        frioMonetaryAmount: 0,
        frioPoints: 0,
        loyaltyMonetaryAmount: 0,
        loyaltyPoints: 0,
        month: '',
        mpgMonetaryAmount: 0,
        mpgPoints: 0,
        posUsabilityMonetaryAmount: 0,
        posUsabilityPoints: 0,
        promotionsMonetaryAmount: 0,
        promotionsPoints: 0,
        totalPoints: 0,
        totalPointsAsMonetaryAmount: 0,
        typeOfTotalPoints: '',
        year: ''
      }
    ]
  },
  error: ''
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateRewardsTypes => {
  switch (type) {
    case REQUEST_DATA_REWARDS:
      return { ...state, isLoading: true }
    case SUCCESS_DATA_REWARDS:
      return { ...state, rewards_data: payload, isLoading: false }
    case ERROR_DATA_REWARDS:
      return {
        ...state,
        error: payload,
        isLoading: false,
        rewards_data: INITIAL_STATE.rewards_data
      }
    default:
      return state
  }
}
