/**********ACTION & ACTION CREATOR***********/

import { AnyAction } from 'redux'
import { objInterface } from '../../../types/General'
import { InitialStateFeatureFlagTypes } from './types'
import { INITIAL_STATE } from './utils'

export const STORE_FEATURE_FLAGS = 'STORE_FEATURE_FLAGS'
export const REQUIRE_FEATURE_FLAGS = 'REQUIRE_FEATURE_FLAGS'
export const GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS'

export const storeFeatureFlags = (payload: objInterface): AnyAction => ({
  type: STORE_FEATURE_FLAGS,
  payload
})

export const requireFeatureFlags = (): AnyAction => ({
  type: REQUIRE_FEATURE_FLAGS,
  payload: { loading: true }
})

/**********REDUCER***********/

export default (
  state = INITIAL_STATE,
  { type, payload }: objInterface
): InitialStateFeatureFlagTypes => {
  switch (type) {
    case STORE_FEATURE_FLAGS:
      return { ...state, ...payload }
    case REQUIRE_FEATURE_FLAGS:
      return { ...state, ...payload }
    default:
      return state
  }
}
