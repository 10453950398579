// Packages
import { combineReducers } from 'redux'

// Reducers
import reducerProfile from '../ducks/profile'
import reducerSalesReport from '../ducks/salesReport/reducer'
import reducerLearning from '../ducks/learning/reducers'
import reducerWorkers from '../ducks/workers'
import reducerFeatureFlags from '../ducks/featureFlags/reducers'
import reducerRewarsReport from '../ducks/rewardsGraphicsReport/reducers'
import reducerRoutes from '../ducks/routes/reducers'
import reducerAuth from '../ducks/auth/reducers'
import reducerLoadBrowser from '../ducks/loadBrowser/reducers'
import reducersDasboard from '../ducks/dashboard/reducers'
import reducerStores from '../ducks/stores/reducers'

// Types
import { InitialStateFeatureFlagTypes } from '../ducks/featureFlags/types'
import { InitialStateProfileTypes } from '../ducks/profile/types'
import { InitialStateSalesReportTypes } from '../ducks/salesReport/types'
import { InitialStateLearningTypes } from '../ducks/learning/types'
import { InitialStateWorkers } from '../ducks/workers/types'
import { InitialStateRewardsTypes } from '../ducks/rewardsGraphicsReport/reducers'
import AuthTypes from '../ducks/auth/types'
import { DESTROY_SESSION } from '../ducks/auth/actions'
import { InitialStateLoadBrowser } from '../ducks/loadBrowser/types'
import { InitialStateDashboard } from '../ducks/dashboard/types'
import RoutesTypes from '../ducks/routes/types'
import { InitialStateStores } from '../ducks/stores/types'

export type reducersTypes = {
  auth: AuthTypes.InitialStateAuth
  profile: InitialStateProfileTypes
  sales_report: InitialStateSalesReportTypes
  rewards_report: InitialStateRewardsTypes
  learning: InitialStateLearningTypes
  workers: InitialStateWorkers
  featureFlags: InitialStateFeatureFlagTypes
  routes: RoutesTypes.RoutesInitialState
  loadBrowser: InitialStateLoadBrowser
  dashboard: InitialStateDashboard
  stores: InitialStateStores
}

// Combine all reducers.
const appReducer = combineReducers<reducersTypes>({
  auth: reducerAuth,
  profile: reducerProfile,
  sales_report: reducerSalesReport,
  rewards_report: reducerRewarsReport,
  learning: reducerLearning,
  workers: reducerWorkers,
  featureFlags: reducerFeatureFlags,
  routes: reducerRoutes,
  loadBrowser: reducerLoadBrowser,
  dashboard: reducersDasboard,
  stores: reducerStores
})
const rootReducer = (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    return appReducer(undefined, { type: undefined })
  }

  return appReducer(state, action)
}
export default rootReducer
