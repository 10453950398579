//Packages
import React from 'react'
import { Col, Row, Tabs } from 'antd'
import { useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'

//Components
import ProfileInfoComponent from '../../components/ProfileInfo'
import Loading from '../../components/Shared/Loading'

// Styled Componets
import {
  StyledProfileContainer,
  StyledProfileImg,
  StyledProfileInformation,
  TabsInformation
} from './styledcomponents'

// Assets
import profile from '../../data/assets/images/profile.png'

// Types
import { reducersTypes } from '../../redux/reducers'
import { InitialStateProfileTypes } from '../../redux/ducks/profile/types'

// Lodash
import { isEmpty } from 'lodash'

const { TabPane } = Tabs

export const callProfileRedux = (
  state: reducersTypes
): InitialStateProfileTypes => state.profile

const ProfileContianer: React.FC = () => {
  const { user_data, isLoading } = useSelector(callProfileRedux)

  return (
    <StyledProfileContainer>
      {!isEmpty(user_data) && !isLoading && (
        <>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <StyledProfileImg>
                <img alt="profile" src={profile} />
                <p>
                  {user_data.getProfile.name +
                    ' ' +
                    user_data.getProfile.middleName +
                    ' ' +
                    user_data.getProfile.familyName}
                </p>
              </StyledProfileImg>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <StyledProfileInformation>
                <TabsInformation defaultActiveKey="1">
                  <TabPane
                    tab={
                      <span>
                        <UserOutlined /> Datos Generales
                      </span>
                    }
                    key="1"
                  >
                    <ProfileInfoComponent
                      user={user_data.getProfile}
                    ></ProfileInfoComponent>
                  </TabPane>
                </TabsInformation>
              </StyledProfileInformation>
            </Col>
          </Row>
        </>
      )}
      <br />
      {isLoading && <Loading />}
    </StyledProfileContainer>
  )
}

export default ProfileContianer
