// Actions
import { SET_STORES_DATA } from '../actions'

// Types
import { ActionCreatorTypes } from '../../../reducers/types'
import { InitialStateStores } from '../types'

const INITIAL_STATE: InitialStateStores = {
  storeID: '',
  marketplace: '',
  franchise: ''
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateStores => {
  switch (type) {
    case SET_STORES_DATA:
      return { ...state, ...payload }
    default:
      return state
  }
}
