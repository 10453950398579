import React from 'react'
import { Table } from './styledComponents'
import UserTypes from '../../types/UserTypes'

type props = {
  user: UserTypes.UserInformation
}

const ProfileInfoComponent: React.FC<props> = ({ user }) => {
  return (
    <>
      <Table id="table-profile">
        <tbody>
          <tr>
            <td>
              <strong>ID Usuario: </strong>
            </td>
            <td>{user.partnerID}</td>
          </tr>
          <tr>
            <td>
              <strong>Nombre </strong>
            </td>
            <td>{user.name + ' ' + user.middleName + ' ' + user.familyName}</td>
          </tr>
          <tr>
            <td>
              <strong>Celular: </strong>
            </td>
            <td>{user.mobilePhone}</td>
          </tr>
          {user.role.roleID !== 3 && (
            <>
              <tr>
                <td>
                  <strong>Correo electrónico: </strong>
                </td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Teléfono local: </strong>
                </td>
                <td>{user.homePhone}</td>
              </tr>
              <tr>
                <td>
                  <strong>Dirección: </strong>
                </td>
                <td>{user.formatAddress}</td>
              </tr>
              <tr>
                <td>
                  <strong>Curp: </strong>
                </td>
                <td>{user.curp}</td>
              </tr>
              <tr>
                <td>
                  <strong>RFC: </strong>
                </td>
                <td>{user.rfc}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default ProfileInfoComponent
