// Actions
import { VARIABLES_SUMMARY_SALES } from '../actions'
// Types
import { ActionCreatorTypes } from '../../../reducers/types'
import { InitialStateDashboard } from '../types'

const INITIAL_STATE = {
  datesSummarySales: [
    {
      id: 'day',
      label: 'Día',
      isActive: false
    },
    {
      id: 'week',
      label: 'Semana',
      isActive: false
    },
    {
      id: 'month',
      label: 'Mes',
      isActive: true
    }
  ]
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateDashboard => {
  switch (type) {
    case VARIABLES_SUMMARY_SALES:
      return { ...state, datesSummarySales: payload }
    default:
      return state
  }
}
