import { InitFeatureFlagsInterface } from '../types'
import { FormatterFeatureFlags } from '../utils/FormatterFeatureFlags'

export const FeatureFlagsComponentsTypes = [
  'report_sales_button_pdf',
  'configuration',
  'dashboard_button_range_sales',
  'shopkeepers'
] as const

const Formatter = new FormatterFeatureFlags()

export const featureFlagsComponents: InitFeatureFlagsInterface[] = [
  Formatter.getFormat('report_sales_button_pdf', 'button pdf sales report'),
  Formatter.getFormat('dashboard_button_range_sales', 'filter in dashboard'),
  Formatter.getFormat('shopkeepers', 'Enable shopkeepers user', 'json')
]
