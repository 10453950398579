import styled from 'styled-components'

export const Table = styled.table`
  td {
    padding: 5px;
  }

  @media all and (max-width: 767px) {
    tbody {
      font-size: 14px;
    }
  }
`
