exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-access-denied-index-tsx": () => import("./../../../src/pages/accessDenied/index.tsx" /* webpackChunkName: "component---src-pages-access-denied-index-tsx" */),
  "component---src-pages-auth-forget-password-index-tsx": () => import("./../../../src/pages/auth/forget-password/index.tsx" /* webpackChunkName: "component---src-pages-auth-forget-password-index-tsx" */),
  "component---src-pages-auth-help-index-tsx": () => import("./../../../src/pages/auth/help/index.tsx" /* webpackChunkName: "component---src-pages-auth-help-index-tsx" */),
  "component---src-pages-auth-index-tsx": () => import("./../../../src/pages/auth/index.tsx" /* webpackChunkName: "component---src-pages-auth-index-tsx" */),
  "component---src-pages-auth-login-index-tsx": () => import("./../../../src/pages/auth/login/index.tsx" /* webpackChunkName: "component---src-pages-auth-login-index-tsx" */),
  "component---src-pages-auth-reset-password-index-tsx": () => import("./../../../src/pages/auth/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-index-tsx" */),
  "component---src-pages-auth-verify-account-index-tsx": () => import("./../../../src/pages/auth/verify-account/index.tsx" /* webpackChunkName: "component---src-pages-auth-verify-account-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interaction-index-tsx": () => import("./../../../src/pages/interaction/index.tsx" /* webpackChunkName: "component---src-pages-interaction-index-tsx" */),
  "component---src-pages-interaction-yolopago-index-tsx": () => import("./../../../src/pages/interaction/yolopago/index.tsx" /* webpackChunkName: "component---src-pages-interaction-yolopago-index-tsx" */),
  "component---src-pages-learning-category-index-tsx": () => import("./../../../src/pages/learning/category/index.tsx" /* webpackChunkName: "component---src-pages-learning-category-index-tsx" */),
  "component---src-pages-learning-index-tsx": () => import("./../../../src/pages/learning/index.tsx" /* webpackChunkName: "component---src-pages-learning-index-tsx" */),
  "component---src-pages-mimodelorama-index-tsx": () => import("./../../../src/pages/mimodelorama/index.tsx" /* webpackChunkName: "component---src-pages-mimodelorama-index-tsx" */),
  "component---src-pages-mimodelorama-store-not-found-index-tsx": () => import("./../../../src/pages/mimodelorama/storeNotFound/index.tsx" /* webpackChunkName: "component---src-pages-mimodelorama-store-not-found-index-tsx" */),
  "component---src-pages-operators-create-operator-index-tsx": () => import("./../../../src/pages/operators/create-operator/index.tsx" /* webpackChunkName: "component---src-pages-operators-create-operator-index-tsx" */),
  "component---src-pages-operators-edit-operator-index-tsx": () => import("./../../../src/pages/operators/edit-operator/index.tsx" /* webpackChunkName: "component---src-pages-operators-edit-operator-index-tsx" */),
  "component---src-pages-operators-index-tsx": () => import("./../../../src/pages/operators/index.tsx" /* webpackChunkName: "component---src-pages-operators-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-reports-beeru-index-tsx": () => import("./../../../src/pages/reports/beeru/index.tsx" /* webpackChunkName: "component---src-pages-reports-beeru-index-tsx" */),
  "component---src-pages-reports-beeru-order-index-tsx": () => import("./../../../src/pages/reports/beeru/order/index.tsx" /* webpackChunkName: "component---src-pages-reports-beeru-order-index-tsx" */),
  "component---src-pages-reports-beespay-detail-ticket-index-tsx": () => import("./../../../src/pages/reports/beespay/detail-ticket/index.tsx" /* webpackChunkName: "component---src-pages-reports-beespay-detail-ticket-index-tsx" */),
  "component---src-pages-reports-beespay-details-by-day-tickets-index-tsx": () => import("./../../../src/pages/reports/beespay/details-by-day-tickets/index.tsx" /* webpackChunkName: "component---src-pages-reports-beespay-details-by-day-tickets-index-tsx" */),
  "component---src-pages-reports-beespay-details-by-days-index-tsx": () => import("./../../../src/pages/reports/beespay/details-by-days/index.tsx" /* webpackChunkName: "component---src-pages-reports-beespay-details-by-days-index-tsx" */),
  "component---src-pages-reports-beespay-details-by-month-index-tsx": () => import("./../../../src/pages/reports/beespay/details-by-month/index.tsx" /* webpackChunkName: "component---src-pages-reports-beespay-details-by-month-index-tsx" */),
  "component---src-pages-reports-beespay-index-tsx": () => import("./../../../src/pages/reports/beespay/index.tsx" /* webpackChunkName: "component---src-pages-reports-beespay-index-tsx" */),
  "component---src-pages-reports-index-tsx": () => import("./../../../src/pages/reports/index.tsx" /* webpackChunkName: "component---src-pages-reports-index-tsx" */),
  "component---src-pages-reports-inventory-index-tsx": () => import("./../../../src/pages/reports/inventory/index.tsx" /* webpackChunkName: "component---src-pages-reports-inventory-index-tsx" */),
  "component---src-pages-reports-rewards-graphics-tsx": () => import("./../../../src/pages/reports/rewards/graphics.tsx" /* webpackChunkName: "component---src-pages-reports-rewards-graphics-tsx" */),
  "component---src-pages-reports-rewards-index-tsx": () => import("./../../../src/pages/reports/rewards/index.tsx" /* webpackChunkName: "component---src-pages-reports-rewards-index-tsx" */),
  "component---src-pages-reports-sales-detail-ticket-index-tsx": () => import("./../../../src/pages/reports/sales/detail-ticket/index.tsx" /* webpackChunkName: "component---src-pages-reports-sales-detail-ticket-index-tsx" */),
  "component---src-pages-reports-sales-details-by-day-tickets-index-tsx": () => import("./../../../src/pages/reports/sales/details-by-day-tickets/index.tsx" /* webpackChunkName: "component---src-pages-reports-sales-details-by-day-tickets-index-tsx" */),
  "component---src-pages-reports-sales-details-by-month-days-index-tsx": () => import("./../../../src/pages/reports/sales/details-by-month-days/index.tsx" /* webpackChunkName: "component---src-pages-reports-sales-details-by-month-days-index-tsx" */),
  "component---src-pages-reports-sales-index-tsx": () => import("./../../../src/pages/reports/sales/index.tsx" /* webpackChunkName: "component---src-pages-reports-sales-index-tsx" */),
  "component---src-pages-reports-sales-operations-type-index-tsx": () => import("./../../../src/pages/reports/sales/operations-type/index.tsx" /* webpackChunkName: "component---src-pages-reports-sales-operations-type-index-tsx" */),
  "component---src-pages-reports-sales-total-operations-index-tsx": () => import("./../../../src/pages/reports/sales/total-operations/index.tsx" /* webpackChunkName: "component---src-pages-reports-sales-total-operations-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */)
}

