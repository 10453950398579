import { gql } from '@apollo/client'

export const GET_RECENT_DAYS_SALES = gql`
  query recentDaysSales($sellerId: ID!) {
    recentDaysSales(sellerId: $sellerId) {
      date
      amount
      counter
    }
  }
`

export const GET_CURRENT_MONTH_SALES = gql`
  query CurrentMonthSales($sellerId: ID!) {
    currentMonthSales(sellerId: $sellerId) {
      monthlySummary {
        counter
        amount
      }
      paymentTypeSummary {
        amount
        paymentType
        counter
      }
    }
  }
`
export const GET_ORDER_BY_DATE = gql`
  query ordersByDate($sellerId: ID!, $from: DateTime!, $to: DateTime) {
    ordersByDate(sellerId: $sellerId, from: $from, to: $to) {
      dateSummary {
        amount
        counter
      }
      dateDetail {
        orderId
        date
        time
        amount
      }
    }
  }
`

export const GET_ORDER_BY_DAYS_FROM_TO = gql`
  query ordersByDate($sellerId: ID!, $from: DateTime!, $to: DateTime!) {
    ordersByDate(sellerId: $sellerId, from: $from, to: $to) {
      dateSummary {
        amount
        counter
      }
      dateDetail {
        orderId
        date
        time
        amount
      }
    }
  }
`
export const GET_ORDER_DETAIL = gql`
  query OrderDetail($orderId: ID!) {
    orderDetail(orderId: $orderId) {
      orderSummary {
        paymentType
        date
        time
        amount
      }
      orderItem {
        skuId
        skuName
        quantity
        price
        sellingPrice
      }
    }
  }
`

export const GET_TICKET_DETAIL = gql`
  query getTicketDetail($ticketID: ID!, $storeId: String, $userId: String) {
    getTicketDetail(ticketID: $ticketID, storeId: $storeId, userId: $userId) {
      ticketSummary {
        ticketID
        numberCard
        dateTransaction
        timeTransaction
        totalTransaction
        paymentType
        userId
      }
      ticketItems {
        skuId
        skuImg
        skuDescription
        skuName
        quantity
        unitPrice
        totalPrice
      }
    }
  }
`

export const GET_DAY_TICKETS = gql`
  query GetTicketList(
    $storeId: ID!
    $from: DateTime!
    $to: DateTime
    $userId: String
  ) {
    getTicketList(storeId: $storeId, from: $from, to: $to, userId: $userId) {
      ticketListSummary {
        fromDate
        toDate
        cashAmount
        cardAmount
        loyaltyAmount
        totalAmount
        totalCounter
      }
      ticketListItems {
        ticketId
        amount
        quantity
        date
        time
      }
    }
  }
`

export const GET_MONTHS = gql`
  query generalDetail(
    $storeId: ID!
    $from: DateTime
    $to: DateTime
    $minPrice: Float
    $maxPrice: Float
    $paymentType: String
    $userId: String
    $permission: String
  ) {
    generalDetail(
      storeId: $storeId
      from: $from
      to: $to
      minPrice: $minPrice
      maxPrice: $maxPrice
      paymentType: $paymentType
      userId: $userId
      permission: $permission
    ) {
      summarySales {
        fromTitleDetail
        toTitleDetail
        totalCash
        totalCard
        totalClubModelorama
        totalSales
        totalNumberOperations
        storeId
      }
      detailSummary {
        item
        totalOperations
        totalAmount
      }
    }
  }
`
export const GET_TOTALS_REPORT = gql`
  query getOperationDetails(
    $storeId: ID!
    $from: DateTime
    $to: DateTime
    $minPrice: Float
    $maxPrice: Float
    $paymentType: String
  ) {
    getOperationDetails(
      storeId: $storeId
      from: $from
      to: $to
      minPrice: $minPrice
      maxPrice: $maxPrice
      paymentType: $paymentType
    ) {
      detailsummary {
        cashAmount
        cashCounter
        cardAmount
        cardCounter
        loyaltyAmount
        loyaltyCounter
        totalAmount
        totalCounter
        mixedLoyaltyCashCounter
        mixedLoyaltyCashAmount
        mixedLoyaltyCardCounter
        mixedLoyaltyCardAmount
        mixedLoyaltyCashCardCounter
        mixedLoyaltyCashCardAmount
      }
    }
  }
`
export const GET_TOTALOPERATIONS_REPORT = gql`
  query TotalOperationsReport($storeId: ID!) {
    TotalOperationsReport(storeId: $storeId) {
      detailsummary {
        date
        cashAmount
        cashCounter
        cardAmount
        cardCounter
        loyaltyAmount
        loyaltyCounter
        totalAmount
        totalCounter
        mixedLoyaltyCashCounter
        mixedLoyaltyCashAmount
        mixedLoyaltyCardCounter
        mixedLoyaltyCardAmount
        mixedLoyaltyCashCardCounter
        mixedLoyaltyCashCardAmount
      }
    }
  }
`
export const GET_TOTAL_SALE_BY_DATE_REPORT = gql`
  query getTotalSaleByDateReport(
    $storeId: ID!
    $from: DateTime!
    $to: DateTime
    $paymentType: String
    $minPrice: Float
    $maxPrice: Float
    $userId: String
  ) {
    getTotalSaleByDateReport(
      storeId: $storeId
      from: $from
      to: $to
      paymentType: $paymentType
      minPrice: $minPrice
      maxPrice: $maxPrice
      userId: $userId
    ) {
      ListItems {
        ticketId
        date
        time
        totalAmount
        operations
        products
        paymentType
        posId
        userId
      }
    }
  }
`

export const GET_BONUS_REPORT = gql`
  query getBonusReport($storeID: String!, $month: String!, $year: String!) {
    getBonusReport(month: $month, year: $year, storeID: $storeID) {
      month
      year
      mpgPoints
      mpgMonetaryAmount
      loyaltyPoints
      loyaltyMonetaryAmount
      promotionsPoints
      promotionsMonetaryAmount
      frioPoints
      frioMonetaryAmount
      posUsabilityPoints
      posUsabilityMonetaryAmount
      totalPoints
      typeOfTotalPoints
      totalPointsAsMonetaryAmount
      beeruPoints
      beeruMonetaryAmount
    }
  }
`
export const GET_INVENTORY_TRACKING = gql`
  query InventoryTracking(
    $requestConfig: requestConfigInput!
    $from: String!
    $to: String
    $timezone: String
  ) {
    inventoryTracking(
      requestConfig: $requestConfig
      from: $from
      to: $to
      timezone: $timezone
    ) {
      skuId
      productName
      incomeCounter
      outcomeCounter
      totalCounter
      isOwnProduct
    }
  }
`

export const GET_PRODUCT_LIST = gql`
  query ProductListV2(
    $requestConfig: requestConfigInputV2!
    $skuId: String
    $eanId: String
    $ownProduct: Boolean
    $updatedPrice: DateRangeProductsUpdatedPrice
    $token: String
  ) {
    productListV2(
      requestConfig: $requestConfig
      skuId: $skuId
      eanId: $eanId
      ownProduct: $ownProduct
      updatedPrice: $updatedPrice
      token: $token
    ) {
      storeProductList {
        productName
        stock
        brandName
        categoryName
        measurementMultiplier
        measurementUnit
        packaging
        updateDatetime
      }
      token
      needUpdate
    }
  }
`

export const GET_REPORT_BEER_U = gql`
  query orderOmnichannel(
    $requestConfig: requestConfigInput!
    $storeID: String!
    $orderID: String
    $token: String
  ) {
    orderOmnichannelList(
      requestConfig: $requestConfig
      storeID: $storeID
      orderID: $orderID
      token: $token
    ) {
      omnichannelOrders {
        orderID
        accountID
        storeID
        totalAmount
        placeOrderDate
        orderType
        statusID
        orderIsComplete
        clientName
        contactPhone
        delivery {
          deliveryDate
          note
          address
          finishDate
        }
        items {
          sku
          quantity
          price
          total
          name
          stockComplete
          brandName
          measurementMultiplier
          measurementUnit
          packaging
          units
          isReturnable
          eansSingle
        }
        commentStatus {
          statusID
          comment
        }
      }
      statusList {
        id
        title
        hexColor
        flowHop
        flowPos
      }
      token
    }
  }
`
