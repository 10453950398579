import {
  configurationRuleTypes,
  featureFlagsTypes,
  InitFeatureFlagsInterface,
  valueTypeDescriptionProps
} from '../types'

class ConfigurationRules {
  getToDenyPath = (pathId: string): configurationRuleTypes => ({
    paths: {
      deny: {
        [pathId]: '*'
      }
    }
  })

  getToDenyMenu = (pathId: string): configurationRuleTypes => ({
    menu: {
      deny: {
        [pathId]: '*'
      }
    }
  })
}

export class FormatterFeatureFlags {
  private rules = new ConfigurationRules()

  private configurationFromRemoteConfig: InitFeatureFlagsInterface = {
    name: 'configuration',
    type: 'permission',
    description: 'Object that configs page and menu directly from RemoteConfig',
    valueType: 'json'
  }

  getConfigurationRemoteConfig = () => this.configurationFromRemoteConfig

  getFormat = (
    featureFlagname: featureFlagsTypes,
    desc: string,
    valueType?: valueTypeDescriptionProps
  ): InitFeatureFlagsInterface => ({
    name: featureFlagname,
    type: 'permission',
    description: `Enable ${desc}`,
    valueType
  })

  getFormatToDenyPath = (
    featureFlagname: featureFlagsTypes,
    pathId: string
  ): InitFeatureFlagsInterface => ({
    name: featureFlagname,
    type: 'permission',
    description: `Enable ${pathId}: path and button in reports`,
    configuration: {
      ...this.rules.getToDenyPath(pathId)
    }
  })

  getFormatToDenyPathAndMenu = (
    featureFlagname: featureFlagsTypes,
    pathId: string
  ): InitFeatureFlagsInterface => ({
    name: featureFlagname,
    type: 'permission',
    description: `Enable ${pathId}: path and menu from layout`,
    configuration: {
      ...this.rules.getToDenyPath(pathId),
      ...this.rules.getToDenyMenu(pathId)
    }
  })
}
