// Packages
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

// Sagas
import rootSaga from '../saga'

// Reducers
import reducers from '../reducers'

export default (state = {}): any => {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]
  const store =
    process.env.GATSBY_ACTIVE_ENV === 'development'
      ? createStore(
          reducers,
          state,
          composeWithDevTools(applyMiddleware(...middlewares))
        )
      : createStore(reducers, state, applyMiddleware(...middlewares))
  sagaMiddleware.run(rootSaga)

  return store
}
