import { InitFeatureFlagsInterface } from '../types'
import { FormatterFeatureFlags } from '../utils/FormatterFeatureFlags'

export const FeatureFlagsMenuListTypes = [
  'dashboard',
  'profile',
  'mimodelorama',
  'interaction',
  'reports',
  'learning',
  'workers',
  'support'
] as const

const Formatter = new FormatterFeatureFlags()

export const featureFlagsMenuList: InitFeatureFlagsInterface[] = [
  Formatter.getFormatToDenyPathAndMenu('dashboard', '/dashboard'),
  Formatter.getFormatToDenyPathAndMenu('profile', '/profile'),
  Formatter.getFormatToDenyPathAndMenu('mimodelorama', '/mimodelorama'),
  Formatter.getFormatToDenyPathAndMenu('interaction', '/interaction'),
  Formatter.getFormatToDenyPathAndMenu('reports', '/reports'),
  Formatter.getFormatToDenyPathAndMenu('learning', '/learning'),
  Formatter.getFormatToDenyPathAndMenu('workers', '/operators'),
  Formatter.getFormatToDenyPathAndMenu('support', '/support')
]
