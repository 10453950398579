import React from 'react'
import Lottie from 'lottie-react'
import * as animationData from '../../../data/assets/json/dots.json'
import { Row, Col } from 'antd'
import { StyledLoadingContainer } from './styledComponents'

const Loading: React.FC = () => {
  return (
    <StyledLoadingContainer>
      <Row justify="center" align="middle" data-testid="loading-indicator">
        <Col>
          <Lottie
            loop={true}
            animationData={animationData}
            style={{ width: 300, height: 300 }}
          />
        </Col>
      </Row>
    </StyledLoadingContainer>
  )
}

export default Loading
