import{ Amplify } from 'aws-amplify'
import config from '../../src/data/env'

const currentConfigAmplify = Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.userPoolId,
      userPoolClientId: config.userPoolWebClientId
    }
  }
})

export default currentConfigAmplify
