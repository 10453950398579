// Actions
import { SAVE_FILTERS, SAVE_DATES, SAVE_STORE } from '../actions'
// Types
import { InitialStateSalesReportTypes } from '../types'
import { ActionCreatorTypes } from '../../../reducers/types'

const INITIAL_STATE = {
  isLoading: false,
  filters: {
    minPrice: '',
    maxPrice: '',
    paymentType: ''
  },
  store: '',
  dates: {
    from: '',
    to: ''
  },
  error: ''
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateSalesReportTypes => {
  switch (type) {
    case SAVE_FILTERS:
      return { ...state, filters: payload }
    case SAVE_DATES:
      return { ...state, dates: payload }
    case SAVE_STORE:
      return { ...state, store: payload }
    default:
      return state
  }
}
