import { InitFeatureFlagsInterface } from '../types'
import { FormatterFeatureFlags } from '../utils/FormatterFeatureFlags'

export const FeatureFlagsAdvanceTypes = ['configuration'] as const

const Formatter = new FormatterFeatureFlags()

export const featureFlagsAdvance: InitFeatureFlagsInterface[] = [
  Formatter.getConfigurationRemoteConfig()
]
