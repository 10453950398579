// Types

import { ActionCreatorTypes } from '../../../reducers/types'
import { ON_LOAD_BROSER } from '../actions'
import { InitialStateLoadBrowser } from '../types'

const INITIAL_STATE = {
  onLoadUser: false
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateLoadBrowser => {
  switch (type) {
    case ON_LOAD_BROSER:
      return { ...state, ...payload }
    default:
      return state
  }
}
