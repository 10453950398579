import { Value } from 'firebase/remote-config'
import { isEmpty } from 'lodash'

import { objInterface } from '../../../../types/General'

import { featureFlagsInit } from '../constant'
import {
  ResFeatureFlagInterface,
  InitialStateFeatureFlagTypes,
  InitFeatureFlagsInterface,
  valueTypeDescriptionProps,
  valueTypeProps
} from '../types'

export type getFeatureFlagObjectType = (
  featureFlag: InitFeatureFlagsInterface
) => ResFeatureFlagInterface

const convertStringToJson = (value: string | undefined): objInterface =>
  value ? JSON.parse(value) : undefined

const convertValue = (
  valueType?: valueTypeDescriptionProps,
  getValue?: Value
): valueTypeProps => {
  switch (valueType) {
    case 'boolean':
      return getValue ? getValue.asBoolean() : undefined

    case 'string':
      return getValue ? getValue.asString() : undefined

    case 'json':
      return getValue ? convertStringToJson(getValue.asString()) : undefined

    case 'number':
      return getValue ? getValue.asNumber() : undefined

    default:
      return getValue ? getValue.asBoolean() : undefined
  }
}

const getConfigRemoteFeatureFlag = (
  featureFlag: InitFeatureFlagsInterface,
  allFeatureFlags: Record<string, Value> | undefined
): ResFeatureFlagInterface => {
  const { name, type, valueType } = featureFlag
  const getValue = allFeatureFlags ? allFeatureFlags[name] : undefined

  return {
    value: convertValue(valueType, getValue),
    source: getValue?.getSource() || 'initRedux',
    type
  }
}

const updateFeatureFlagsEnviorements = (
  featureFlag: InitFeatureFlagsInterface,
  data: InitialStateFeatureFlagTypes,
  allFeatureFlags: Record<string, Value> | undefined
): InitialStateFeatureFlagTypes => {
  const { name } = featureFlag

  const flags = {
    ...data.flags,
    [name]: getConfigRemoteFeatureFlag(
      { ...featureFlag, name },
      allFeatureFlags
    )
  }

  return {
    flags,
    isRemote: false,
    loading: false
  }
}

export const getArrayPayloadFeatureFlag = (
  allFeatureFlags: objInterface
): InitialStateFeatureFlagTypes => {
  let payload: InitialStateFeatureFlagTypes = {
    flags: {},
    isRemote: false,
    loading: false
  }

  featureFlagsInit.forEach((featureFlag) => {
    payload = {
      ...updateFeatureFlagsEnviorements(featureFlag, payload, allFeatureFlags)
    }
  })
  const isRemote = !isEmpty(allFeatureFlags)
  const loading = false
  return { ...payload, isRemote, loading }
}

export const INITIAL_STATE = getArrayPayloadFeatureFlag({})
