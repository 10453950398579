/**********ACTION & ACTION CREATOR***********/

import { REQUIRE_FEATURE_FLAGS, STORE_FEATURE_FLAGS } from '..'
import { objInterface } from '../../../../types/General'
import { InitialStateFeatureFlagTypes } from '../types'
import { INITIAL_STATE } from '../utils'

/**********REDUCER***********/

export default (
  state = INITIAL_STATE,
  { type, payload }: objInterface
): InitialStateFeatureFlagTypes => {
  switch (type) {
    case STORE_FEATURE_FLAGS:
      return { ...state, ...payload }
    case REQUIRE_FEATURE_FLAGS:
      return { ...state, ...payload }
    default:
      return state
  }
}
