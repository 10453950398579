import { InitFeatureFlagsInterface } from '../types'
import { FormatterFeatureFlags } from '../utils/FormatterFeatureFlags'

export const FeatureFlagsPagesTypes = [
  'APP_PEM_REPORTS_BEES_PAY',
  'APP_PEM_REPORTS_REWARDS',
  'APP_PEM_REPORTS_INVENTORY',
  'APP_PEM_REPORTS_BEERU'
] as const

const Formatter = new FormatterFeatureFlags()

export const featureFlagsPages: InitFeatureFlagsInterface[] = [
  Formatter.getFormatToDenyPath('APP_PEM_REPORTS_REWARDS', '/rewards'),
  Formatter.getFormatToDenyPath('APP_PEM_REPORTS_BEES_PAY', '/beespay'),
  Formatter.getFormatToDenyPath('APP_PEM_REPORTS_INVENTORY', '/inventory'),
  Formatter.getFormatToDenyPath('APP_PEM_REPORTS_BEERU', '/beeru')
]
