import { ActionCreatorTypes } from '../../../reducers/types'
import {
  UPDATE_ROUTE_SELECTED,
  UPDATE_ROUTE_WITH_FEATURE_FLAGS
} from '../actions'

import RoutesTypes from '../types'

const INITIAL_STATE: RoutesTypes.RoutesInitialState = {
  loading: true,
  routesList: [],
  error: null
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): RoutesTypes.RoutesInitialState => {
  switch (type) {
    case UPDATE_ROUTE_WITH_FEATURE_FLAGS:
      return {
        ...state,
        ...payload
      }
    case UPDATE_ROUTE_SELECTED:
      return {
        ...state,
        routesList: [
          ...state.routesList.map((item) => {
            item.selected = false
            if (`${item.path}/` == String(payload)) {
              item.selected = true
            }
            return item
          })
        ],
        loading: false
      }

    default:
      return state
  }
}
