// Packages
import fetch from 'isomorphic-fetch'
import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client'

// Assets
import config from '../data/env'
import cache from './cache'
import authLink from './authLink'
import errorLink from './errorLink'

const httpLink = new HttpLink({
  uri: `https://${config.baseServiceUri}`,
  fetch
})

const httpLinkReports = new HttpLink({
  uri: `https://${config.baseServiceUriReports}`,
  fetch
})

const otherLinks = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'api-instore',
  httpLinkReports,
  authLink.concat(httpLink)
)

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, otherLinks]),
  cache,
  credentials: 'include'
})

export default client
