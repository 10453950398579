import { Tabs } from 'antd'
import styled from 'styled-components'

export const StyledProfileContainer = styled.div`
  margin: 2%;
`
export const StyledProfileImg = styled.div`
  background-color: white;
  padding: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  img {
    width: 80px;
    margin: 15px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }

  @media all and (max-width: 480px) {
    height: 180px;
  }
`
export const StyledProfileInformation = styled.div`
  background-color: white;
  padding: 15px;
  height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .ant-tabs {
    width: 90%;
  }
  @media all and (min-width: 300px) and (max-width: 568px) {
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      display: none;
    }
  }
`

export const TabsInformation = styled(Tabs)``
