// Packages
import { all, call, put, takeEvery } from 'redux-saga/effects'
// GraphQL
import { GET_BONUS_REPORT } from '../../../../graphql/queries/Reports'
// Actions
import { REQUEST_DATA_REWARDS } from '../actions'
// Actions Creatots
import { successDataRewards, errorDataRewards } from '../actionsCreators'
// Assets
import client from '../../../../gatsby-theme-apollo/client'
// Types
import { ActionCreatorTypes } from '../../../reducers/types'

function* workerGetRewardsReport(data: ActionCreatorTypes): Generator {
  const { payload } = data
  try {
    const dataRewards = yield call(async () => {
      const response = await client.query({
        query: GET_BONUS_REPORT,
        variables: payload,
        context: { clientName: 'api-instore' }
      })
      return response.data
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(successDataRewards(dataRewards))
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(errorDataRewards(error))
  }
}

export function* watcherGetRewardsReport(): Generator {
  yield all([takeEvery(REQUEST_DATA_REWARDS, workerGetRewardsReport)])
}
