// Packages
import _ from 'lodash'

// Assets
import baseConfig from './base.json'
import developmentConfig from './development.json'
import qaConfig from './qa.json'
import productionConfig from './production.json'
import stagingConfig from './staging.json'

const configs: { [x: string]: { [x: string]: any } } = {
  development: _.merge({}, baseConfig, developmentConfig),
  qa: _.merge({}, baseConfig, developmentConfig, qaConfig),
  staging: _.merge({}, baseConfig, developmentConfig, qaConfig, stagingConfig),
  production: _.merge(
    {},
    baseConfig,
    developmentConfig,
    qaConfig,
    stagingConfig,
    productionConfig
  )
}

const environment = process.env.GATSBY_ACTIVE_ENV || 'development'

const config = configs[environment]
// Enviroment variables
config.UserPhrase = process.env.GATSBY_AMPLIFY_USER_PHRASE
config.baseServiceUri = process.env.GATSBY_URI_SERVICE
config.GATSBY_SIHAY_ENROLLMENT_URL = process.env.GATSBY_SIHAY_ENROLLMENT_URL
;(config.region = process.env.GATSBY_AMPLIFY_REGION_EMPRESARIO),
  (config.userPoolId = process.env.GATSBY_AMPLIFY_USER_POOL_ID_EMPRESARIO),
  (config.userPoolWebClientId =
    process.env.GATSBY_AMPLIFY_USER_POOL_WEB_CLIENT_ID_EMPRESARIO),
  (config.identityPoolWebClientId =
    process.env.GATSBY_AMPLIFY_IDENTITY_POOL_WEB_CLIENT_ID_EMPRESARIO),
  (config.s3YoloPAgoFilesBucket =
    process.env.S3_BUCKET_EMPRESARIOS_WEB_YOLOPAGO_FILES)

export default config
