// Packages
import { ApolloLink } from '@apollo/client'

// Assets
import { getAccessTokenReturn } from '../../utils/storage'

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = getAccessTokenReturn()
    ? `Bearer ${getAccessTokenReturn()}`
    : ''

  operation.setContext(({ headers }: { headers: Headers }) => ({
    headers: {
      ...headers,
      authorization: accessToken
    }
  }))

  return forward(operation)
})

export default authLink
