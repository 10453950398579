// Actions
import { SAVE_TYPE_MEDIA, SAVE_CATEGORY_LEARNING } from '../actions'

// Types
import { InitialStateLearningTypes } from '../types'
import { ActionCreatorTypes } from '../../../reducers/types'

// Reducer

const INITIAL_STATE = {
  isLoading: false,
  typeMedia: 'video',
  categoryLearning: {},
  error: ''
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateLearningTypes => {
  switch (type) {
    case SAVE_TYPE_MEDIA:
      return { ...state, typeMedia: payload }
    case SAVE_CATEGORY_LEARNING:
      return { ...state, categoryLearning: payload }
    default:
      return state
  }
}
