/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/es_ES'
import 'moment/locale/es-mx'
import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css'

export const wrapRootElement = ({ element }) => (
  <ConfigProvider locale={locale}>{element}</ConfigProvider>
)
