// Packages
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { callProfileRedux } from '../../../containers/ProfileContainer'

// Assets
import client from '../../../gatsby-theme-apollo/client'

// GraphQL
import { GET_PROFILE } from '../../../graphql/queries/profile'

// Types
import { ActionCreatorTypes } from '../../reducers/types'
import { addMockAcces } from './addDefaultAcces'
import {
  InitialStateProfileTypes,
  PayloadDataProfile,
  PayloadErrorProfile
} from './types'

import defaultPermissionInventory from './defaultPermissionInventory.json'
import { ProfileInterface } from '../../../types/ProfileTypes'

/**********SAGAS***********/

function* workerGetProfiles(): Generator {
  try {
    const userData: any = yield select(callProfileRedux)

    const data = yield call(async () => {
      if (userData.user_data.getProfile) {
        return userData.user_data
      }

      const response = await client.query<ProfileInterface>({
        query: GET_PROFILE,
        fetchPolicy: 'no-cache'
      })
      //return response.data
      return addMockAcces(response.data, defaultPermissionInventory, 'Reportes')
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(successDataProfile(data))
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(errorDataProfile(error))
  }
}

export function* watcherGetProfiles(): Generator {
  yield all([takeEvery(REQUEST_DATA_PROFILE, workerGetProfiles)])
}

/**********ACTION & ACTION CREATOR***********/

export const REQUEST_DATA_PROFILE = 'REQUEST_DATA_PROFILE'
export const SUCCESS_DATA_PROFILE = 'SUCCESS_DATA_PROFILE'
export const ERROR_DATA_PROFILE = 'ERROR_DATA_PROFILE'

export const requestDataProfile = (): ActionCreatorTypes => ({
  type: REQUEST_DATA_PROFILE
})
export const successDataProfile = (
  payload: PayloadDataProfile
): ActionCreatorTypes => ({
  type: SUCCESS_DATA_PROFILE,
  payload
})

export const errorDataProfile = (
  payload: PayloadErrorProfile
): ActionCreatorTypes => ({
  type: ERROR_DATA_PROFILE,
  payload
})

/**********REDUCER***********/

const INITIAL_STATE = {
  isLoading: true,
  user_data: {},
  error: ''
}

export default (
  state = INITIAL_STATE,
  { type, payload }: ActionCreatorTypes
): InitialStateProfileTypes => {
  switch (type) {
    case REQUEST_DATA_PROFILE:
      return { ...state, isLoading: false }
    case SUCCESS_DATA_PROFILE:
      return { ...state, user_data: payload, isLoading: false }
    case ERROR_DATA_PROFILE:
      return { ...state, error: payload, isLoading: false }
    default:
      return state
  }
}
