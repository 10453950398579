const addMockModulesToPermissions = (
  data: any,
  defaulPermissions: any,
  name: string
) => {
  const { permissions } = data.getProfile
  const permissionGroup = permissions.find(
    (permission: any) => permission.groupName === name
  )
  permissionGroup.modules.push(...defaulPermissions.permissions)
  return data
}

const addMockSubRoutesToDashboardRoutes = (
  data: any,
  defaulPermissions: any,
  name: string
) => {
  const { dashboardRoutes } = data.getProfile
  const routeFound = dashboardRoutes.find(
    (route: any) => route.nameRoute === name
  )
  routeFound.subRoutes.push(...defaulPermissions.subRoutes)
  return data
}

export const addMockAcces = (
  data: any,
  defaulPermissions: any,
  name: string
) => {
  const data1 = addMockModulesToPermissions(data, defaulPermissions, name)
  return addMockSubRoutesToDashboardRoutes(data1, defaulPermissions, name)
}
