import { InitFeatureFlagsInterface } from '../types'
import { featureFlagsAdvance, FeatureFlagsAdvanceTypes } from './advance'
import {
  featureFlagsComponents,
  FeatureFlagsComponentsTypes
} from './components'
import { featureFlagsMenuList, FeatureFlagsMenuListTypes } from './menuList'
import { FeatureFlagsPagesTypes, featureFlagsPages } from './pages'

export const featureFlagsInit: InitFeatureFlagsInterface[] = [
  ...featureFlagsMenuList,
  ...featureFlagsPages,
  ...featureFlagsComponents,
  ...featureFlagsAdvance
]

export const NamesFeatureFlagsTypes = [
  ...FeatureFlagsMenuListTypes,
  ...FeatureFlagsPagesTypes,
  ...FeatureFlagsComponentsTypes,
  ...FeatureFlagsAdvanceTypes
] as const
