import { gql } from '@apollo/client'

export const GET_PROFILE = gql`
  {
    getProfile {
      partnerID
      userID
      rfc
      name
      middleName
      familyName
      mobilePhone
      homePhone
      email
      birthdate
      zipCode
      state
      municipality
      neighborhood
      street
      extNumber
      curp
      complement
      formatAddress
      gender {
        genderID
        description
      }
      stores {
        storeID
        lastReassignationDate
        agency {
          agencyID
          agencyName
          createdAt
          lastUpdatedAt
          createdBy
          lastUpdatedBy
        }
        name
        phone
        countryCode
        vtexMarketplace
        vtexFranchise
        postalCode
        state
        city
        neighborhood
        street
        buildingNumber
        complement
        reference
        latitude
        longitude
        status {
          statusID
          description
        }
        storeType {
          storeTypeID
          description
          mawEnable
        }
        storeSegment {
          storeSegmentID
          description
          createdBy
          createdAt
          lastUpdatedAt
          lastUpdatedBy
        }
        device {
          deviceID
          storeID
          vendor
          model
          createdBy
          createdAt
          lastUpdatedAt
          lastUpdatedBy
        }
        formatAddress
        openingDate
        createdAt
        lastUpdatedAt
        createdBy
        lastUpdatedBy
      }
      permissions {
        groupName
        groupID
        modules {
          moduleName
          descriptionModule
          appModulePemID
          actions {
            actionID
            actionName
            actionDescription
          }
        }
      }
      dashboardRoutes {
        nameRoute
        path
        subRoutes {
          nameRoute
          path
        }
      }
      role {
        roleID
      }
    }
  }
`
